import './App.css';
import Example from './Example';
import CarPartsWebsite from './Test';

const App: React.FC = () => {
  return (
    <Example/>
    // <CarPartsWebsite/>
  );
};

export default App;
